import { IPatientUpcomingConsultationDto } from '@/Common/interfaces';
import Modal from '../../Common/Modal';
import { AlertViewModel } from '../AlertViewModel';
import { ReceiveVideoCall } from './ReceiveVideoCallFromDoctor';
import { ConsultationTypeEnum } from '@/Common/enums';
import { ChildModalViewModel } from '@/ViewModels/ChildModalViewModel';
import { PetModalViewModel } from '@/ViewModels/PetModalViewModel';
import { fetch, FetchError } from '@/Common/fetch';
import { Log } from '@/Common/Log';

export class SelectPatientViewModel {
    public receiveVideoCall: ReceiveVideoCall;
    public profileState: KnockoutObservable<string>;
    public childModal: ChildModalViewModel;
    public petModal: PetModalViewModel;
    public alertModal: AlertViewModel;
    public usersUpcomingBookingConsultationMap: IPatientUpcomingConsultationDto[];

    constructor(consultationType: ConsultationTypeEnum) {
        const self = this;
        self.usersUpcomingBookingConsultationMap = [];
        self.alertModal = new AlertViewModel();
        self.receiveVideoCall = new ReceiveVideoCall();
        self.childModal = new ChildModalViewModel();
        self.petModal = new PetModalViewModel();

        self.fetchUpcomingConsultations(consultationType).then();
    }

    private fetchUpcomingConsultations = async (
        consultationType: ConsultationTypeEnum,
    ) => {
        fetch<IPatientUpcomingConsultationDto[]>(
            `/api/patients/has-upcoming-video-consultation-per-user/${consultationType}`,
            'GET',
        )
            .then((response: IPatientUpcomingConsultationDto[]) => {
                this.usersUpcomingBookingConsultationMap = response;
            })
            .catch((err: FetchError<any>) => {
                if (err.status === 401) window.location.reload();

                Log.error(
                    'Error when fetching upcoming consultations for patient',
                    null,
                    err,
                );
            });
    };

    public onSelectPatient = async (
        consultationType: string,
        handledBy: string,
        continuationUrl: string,
        patientUid: string,
        childId: number = null,
    ) => {
        const isPhysioTherapyVideo = [
            ConsultationTypeEnum[
                ConsultationTypeEnum.PhysiotherapistStartupVideo
            ],
            ConsultationTypeEnum[
                ConsultationTypeEnum.PhysiotherapistCheckupVideo
            ],
        ].includes(consultationType);

        if (isPhysioTherapyVideo && childId !== null) {
            try {
                const correctConsultationTypeForChild: ConsultationTypeEnum =
                    await fetch(
                        `/api/products/v2/${childId}/${handledBy}`,
                        'GET',
                    );

                if (
                    consultationType !=
                    (correctConsultationTypeForChild as unknown as string)
                ) {
                    const url = `${continuationUrl}/${correctConsultationTypeForChild}?viaselectpatient=true&patientUid=${patientUid}`;
                    await this.fetchUpcomingConsultations(
                        correctConsultationTypeForChild,
                    );

                    this.validateIfPatientHaveUpcomingConsultationBeforeContinuing(
                        url,
                        patientUid,
                    );
                }
            } catch (err) {
                Log.error(
                    `Error when checking correct consultation type for child in SelectPatientViewModel. Handled by ${handledBy}. ChildID: ${childId}`,
                    null,
                    err,
                );
            }
        } else {
            const url = `${continuationUrl}/${consultationType}?viaselectpatient=true&patientUid=${patientUid}`;
            this.validateIfPatientHaveUpcomingConsultationBeforeContinuing(
                url,
                patientUid,
            );
        }
    };

    public validateIfPatientHaveUpcomingConsultationBeforeContinuing = (
        continuationUrl: string,
        patientUid: string,
    ) => {
        const self = this;
        const selectedUser = self.usersUpcomingBookingConsultationMap.find(
            (x) => x.UID == patientUid,
        );
        if (selectedUser && selectedUser.HasUpcomingVideoConsultation) {
            Modal.show('youAlreadyHaveUpcomingConsultation');
            return;
        }

        document.location.href = continuationUrl;
    };

    public closeYouAlreadyHaveUpcomingConsultation = () => {
        Modal.close('youAlreadyHaveUpcomingConsultation');
    };
}
